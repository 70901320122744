import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";

import ImagesScroll from "../components/images-scroll";

import { useTranslate } from "../contexts/translate";

import { Column, Container } from "../ui-components/columns/components";
import { FullHeightSection } from "../ui-components/sections";
import { GigaTitle } from "../ui-components/texts";
import { deviceType, touchWork } from "../utils";

const Projects = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();

	const [selectedIndex, setSelectedIndex] = useState(0);
	const [startAnimation, setStartAnimation] = useState(false);
	const [inverse, setInverse] = useState(false);
	const [closeAnimation, setCloseAnimation] = useState("text-animation");

	useEffect(() => {
		document.title = "Projects - Cutting Edge Digital Creative Studio";
	});

	const items = [
		{
			title: "Villafago",
			link: "/projects/villafago",
			filters: ["Content, Digital Products"],
			image: require("../media/images/projects-page/villafago.png").default,
		},
		{
			title: "Taormina Caffè",
			link: "/projects/taormina-caffe",
			filters: ["Content, Digital Products"],
			image: require("../media/images/projects-page/taormina.png").default,
		},
		{
			title: "Top Building Group",
			link: "/projects/topbuildinggroup",
			filters: ["Digital Products"],
			image: require("../media/images/projects-page/tbg.png").default,
		},
		{
			title: "Capable",
			link: "/projects/capable",
			filters: ["Digital Products"],
			image: require("../media/images/projects-page/capable.png").default,
		},
		{
			title: "LocoMeal",
			link: "/projects/locomeal",
			filters: ["Digital Products"],
			image: require("../media/images/projects-page/Locomeal.png").default,
		},
		{
			title: "Ride 5",
			link: "/projects/ride-5",
			filters: ["Digital Products"],
			image: require("../media/images/projects-page/ride5.png").default,
		},
		{
			title: "LiveMeFive",
			link: "/projects/livemefive",
			filters: ["Digital Products"],
			image: require("../media/images/projects-page/Liveme5.png").default,
		},
		{
			title: "Themu",
			link: "/projects/themu",
			filters: ["Design", "Digital Products"],
			image: require("../media/images/projects-page/Themu.png").default,
		},
		{
			title: "Raiontech",
			link: "/projects/raiontech",
			filters: ["Design", "Digital Products"],
			image: require("../media/images/projects-page/Raiontech.png").default,
		},
		{
			title: "Giglio",
			link: "/projects/giglio",
			filters: ["Content"],
			image: require("../media/images/projects-page/giglio.png").default,
		},
		{
			title: "Ohoskin",
			link: "/projects/ohoskin",
			filters: ["Content"],
			image: require("../media/images/projects-page/ohoskin.png").default,
		},
		{
			title: "Micidial",
			link: "/projects/micidial",
			filters: ["Design", "Digital Products"],
			image: require("../media/images/projects-page/micidial.png").default,
		},
		{
			title: "World Padel<br/>Store",
			filters: ["Content", "Digital Marketing", "Digital Products"],
			link: "/projects/world-padel-store",
			image: require("../media/images/projects-page/world-padel-store.png")
				.default,
		},
		{
			title: "Omnama",
			link: "/projects/omnama",
			filters: ["Design"],
			image: require("../media/images/projects-page/omnama.png").default,
		},
		{
			title: "Biz Academy",
			link: "/projects/biz-academy",
			filters: ["Digital Products", "Design"],
			image: require("../media/images/projects-page/biz-academy.png").default,
		},
		{
			title: "US Lecce",
			link: "/projects/us-lecce",
			filters: ["Content"],
			image: require("../media/images/projects-page/us-lecce.png").default,
		},
		{
			title: "Clinicsoul",
			link: "/projects/clinicsoul",
			filters: ["Design", "Digital Products"],
			image: require("../media/images/projects-page/clinicsoul.png").default,
		},
		{
			title: "Asmundo di<br/>Gisira",
			link: "/projects/asmundo-di-gisira",
			filters: ["Content", "Digital Marketing"],
			image: require("../media/images/projects-page/gisira.png").default,
		},
		{
			title: "Etna Dolce",
			link: "/projects/etna-dolce",
			filters: ["Design", "Content"],
			image: require("../media/images/projects-page/etna-dolce.png").default,
		},
		{
			title: "Superstylin",
			link: "/projects/superstylin",
			filters: ["Digital Products", "Digital Marketing"],
			image: require("../media/images/projects-page/superstylin.png").default,
		},
		{
			title: "Peasy",
			link: "/projects/peasy",
			filters: ["Digital Products", "Design"],
			image: require("../media/images/projects-page/peasy.png").default,
		},
		{
			title: "Rica",
			link: "/projects/rica",
			filters: ["Content"],
			image: require("../media/images/projects-page/rica.png").default,
		},
		{
			title: "Correre Naturale",
			link: "/projects/correre-naturale",
			filters: ["Digital Products", "Digital Marketing"],
			image: require("../media/images/projects-page/correre-naturale.png")
				.default,
		},
		{
			title: "Ofit",
			link: "/projects/ofit",
			filters: ["Design", "Content"],
			image: require("../media/images/projects-page/ofit.png").default,
		},
		{
			title: "Antonio Quaglietta",
			link: "/projects/antonio-quaglietta",
			filters: ["Digital Marketing"],
			image: require("../media/images/projects-page/antonio-quaglietta.png")
				.default,
		},
		{
			title: "Vitarmonyl",
			link: "/projects/vitarmonyl",
			filters: ["Digital Marketing"],
			image: require("../media/images/projects-page/vitarmonyl.png").default,
		},
		{
			title: "Max Boutique",
			link: "/projects/max-boutique",
			filters: ["Content", "Digital Marketing"],
			image: require("../media/images/projects-page/max-boutique.png").default,
		},
		{
			title: "Trident",
			link: "/projects/trident",
			filters: ["Content"],
			image: require("../media/images/projects-page/trident.png").default,
		},
		{
			title: "CIPS",
			link: "/projects/cips",
			filters: ["Content"],
			image: require("../media/images/projects-page/cips.png").default,
		},
		{
			title: "Costumi di Sicilia",
			link: "/projects/costumi-di-sicilia",
			filters: ["Content"],
			image: require("../media/images/projects-page/cds.png").default,
		},
		{
			title: "Papini Store",
			link: "/projects/papini-store",
			filters: ["Content"],
			image: require("../media/images/projects-page/panini.png").default,
		},
		{
			title: "The Rebel Company",
			link: "/projects/the-rebel-company",
			filters: ["Content"],
			image: require("../media/images/projects-page/rebel.png").default,
		},
	];

	useEffect(() => {
		setNextPageHref("/talent-sourcing");
		setNextPageName(
			translations === null ? "" : translations.navbar.links.talentSourcing
		);
	}, [translations]);

	useEffect(() => {
		// if (deviceType() !== 'desktop') return;
		if (touchWork() || window.innerWidth <= 1150) return;

		window.addEventListener("load", () => {
			window.scrollTo({ top: 0 });
			document.querySelector("body").style.overflow = "hidden";
		});

		window.addEventListener("wheel", (e) => {
			document.querySelector("body").style.overflow = "hidden";

			try {
				if (e.deltaY >= 50) {
					setStartAnimation(true);
					setInverse(true);
				}

				if (e.deltaY <= -50) {
					setStartAnimation(true);
					setInverse(false);
				}
			} catch (err) {}
		});

		return function cleanup() {
			window.removeEventListener("load", () => {});
			window.removeEventListener("wheel", () => {});
		};
	}, []);

	useEffect(() => {
		if (!startAnimation) return;

		setStartAnimation(false);
		setTimeout(() => {
			// close text animation
			setCloseAnimation("close-text-animation fast");

			setTimeout(() => {
				// update selected index
				if (inverse) {
					if (selectedIndex === 0) setSelectedIndex(1);
					if (selectedIndex === 1) setSelectedIndex(2);
					if (selectedIndex === 2) setSelectedIndex(3);
				} else {
					if (selectedIndex === 3) setSelectedIndex(2);
					if (selectedIndex === 2) setSelectedIndex(1);
					if (selectedIndex === 1) setSelectedIndex(0);
				}

				// open text animation
				setTimeout(() => {
					setCloseAnimation("text-animation fast");
				}, 500);
			}, 500);
		}, 500);
	}, [startAnimation]);

	const [filter, setFilter] = useState(null);
	const [filteredItems, setFilteredItems] = useState([]);

	const filters = [
		"Design",
		"Digital Marketing",
		"Digital Products",
		"Content",
	];

	useEffect(() => {
		if (filter === null) return setFilteredItems(items);

		const filtered = items.filter((i) => i.filters.includes(filter));

		setSelectedIndex(0);
		setFilteredItems(filtered);
	}, [filter]);

	useEffect(() => {
		if (!startAnimation) return;

		setTimeout(() => {
			// update selected index
			if (inverse) {
				for (let i = 0; i < filteredItems.length; i++) {
					if (selectedIndex === filteredItems.length - 1)
						return setCloseAnimation("text-animation");
					if (selectedIndex === i) setSelectedIndex(i + 1);
				}
			} else {
				for (let i = 1; i < filteredItems.length; i++) {
					if (selectedIndex === 0) return setCloseAnimation("text-animation");
					if (selectedIndex === i) setSelectedIndex(i - 1);
				}
			}

			setStartAnimation(false);
		}, 500);
	}, [startAnimation]);

	return (
		<>
			<div
				className="projects-page-component projects-wrapper"
				style={{ width: "100%", background: "#fff" }}
			>
				{touchWork() || window.innerWidth <= 1150 ? (
					<section
						className="theme-light projects-list"
						style={{ background: "#fff" }}
					>
						<div className="portfolio-filters">
							<span>Filtra per</span>
							<div className="items">
								<a
									href="#"
									className={filter === null ? "active" : null}
									onClick={() => setFilter(null)}
								>
									Tutti
								</a>
								{filters.map((item, index) => (
									<a
										href="#"
										className={filter === item ? "active" : null}
										key={index}
										onClick={() => setFilter(item)}
									>
										{item}
									</a>
								))}
							</div>
						</div>

						{filteredItems.map((item, index) => (
							<GigaTitle
								as="a"
								key={index}
								className="add-margin link-portfolio"
								href={item.link}
								dangerouslySetInnerHTML={{
									__html: `<span>${index + 1}</span> ${item.title}`,
								}}
							></GigaTitle>
						))}
					</section>
				) : (
					<FullHeightSection
						className="d-flex theme-light"
						style={{ background: "#fff" }}
					>
						<Container className="mt-auto mb-auto">
							<div className="wrapper mid align-items-center">
								<Column className="w-50 projects-page-titles">
									{/* filters */}
									<div className="portfolio-filters">
										<span>Filtra per</span>
										<a
											href="#"
											className={filter === null ? "active" : null}
											onClick={() => setFilter(null)}
										>
											Tutti
										</a>
										{filters.map((item, index) => (
											<a
												href="#"
												className={filter === item ? "active" : null}
												key={index}
												onClick={() => setFilter(item)}
											>
												{item}
											</a>
										))}
									</div>
									{filteredItems.map((item, index) => (
										<GigaTitle
											key={index}
											onClick={() => (window.location.href = item.link)}
											className={`${
												selectedIndex === index
													? "active"
													: selectedIndex + 1 === index
													? "next"
													: selectedIndex - 1 === index
													? "prev"
													: "d-none"
											}`}
											dangerouslySetInnerHTML={{
												__html: `<span>${index + 1}</span> ${item.title}`,
											}}
										></GigaTitle>
									))}
								</Column>
								<Column className="w-50 projects-images">
									<ImagesScroll
										images={filteredItems}
										selectedIndex={selectedIndex}
									/>
								</Column>
							</div>
						</Container>
					</FullHeightSection>
				)}
			</div>
			{touchWork() ? null : (
				<div
					className="disabled-on-mobile"
					style={{ background: "#fff", width: "100%", height: 1000 }}
				></div>
			)}
		</>
	);
};

export default Projects;
