import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";

import {
	LargeText,
	SmallHeading,
	SmallTitle,
	MediumTitle,
} from "../../ui-components/texts";

// import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import PeopleOfMasamune from "../../components/people-of-masamune";

// images
import imageCover from "../../media/images/portfolio-images/livemefive/image-cover.jpg";
import { ImageLeftWithText } from "./components-content";
import { useTranslate } from "../../contexts/translate";

const Livemefive = ({
	setNextPageHref = () => {},
	setNextPageName = () => {},
}) => {
	const { translations } = useTranslate();

	useEffect(() => {
		setNextPageHref("/projects/micidial");
		setNextPageName("Micidial");
		document.title = "LiveMeFive - Cutting Edge Digital Creative Studio";
	}, []);

	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../../media/team-images/Samuele Olindo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
	];

	return (
		<div className="project-page project-page-ohoskin">
			<CoverPorfolio
				background="#fff"
				title="LiveMeFive"
				text={translations && translations.livemefive.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"Livemefive"}
				services={["Coding"]}
				timeframe={"2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.livemefive.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.livemefive.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<section className="theme-light" style={{ background: "#fff" }}>
				<Container>
					<div className="wrapper fluid  pb-0">
						<Column>
							<MediumTitle>
								{translations && translations.livemefive.sections[0].title}
							</MediumTitle>
							<LargeText>
								{translations && translations.livemefive.sections[0].text}
							</LargeText>
							<LargeText>
								{translations && translations.livemefive.sections[0].text1}
							</LargeText>
						</Column>
					</div>
				</Container>

				<Container className="theme-dark" style={{ background: "#FB322D" }}>
					<div className="wrapper fluid pb-5">
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/livemefive/1.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/livemefive/2.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
						<Column>
							<video
								width="100%"
								src={
									require("../../media/images/portfolio-images/livemefive/3.mp4")
										.default
								}
								loop={true}
								muted={true}
								autoPlay={true}
								controls={false}
								playsInline={true}
							></video>
						</Column>
					</div>
				</Container>
			</section>

			<ImageLeftWithText
				className="theme-light"
				color="#fff"
				//title={translations && translations.ohoskin.sections[0].title}
				texts={[translations && translations.livemefive.sections[1].text]}
				image={
					require("../../media/images/portfolio-images/livemefive/image-sx.png")
						.default
				}
			/>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Livemefive.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Livemefive;
