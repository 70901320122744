import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { ScrollXImages } from "../../ui-components/force-scroll-x";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

// images
import imageCover from "../../media/images/portfolio-images/ride/image-cover.png";
import { useTranslate } from "../../contexts/translate";

const Ride = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/projects/max-boutique");
		setNextPageName("Max Boutique");
		document.title = "Ride 5 - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();

	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../../media/team-images/Samuele Olindo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
		{
			fullname: "Gabriele Lanzafame",
			image: require("../../media/team-images/Gabriele Lanzafame.jpg").default,
			role: translations === null ? "" : translations.about.peoples[9].role,
			description:
				translations === null ? "" : translations.about.peoples[9].description,
		},
	];

	return (
		<div className="project-page project-page-us-lecce">
			<CoverPorfolio
				background="#2F2E2F"
				className="theme-dark"
				title="Ride 5"
				text={translations && translations.ride.intro}
				image={imageCover}
				white={true}
			/>

			<DetailsProject
				client={"Ride 5"}
				services={["Coding"]}
				timeframe={"2023"}
				introduction={
					<>
						<LargeText>
							{translations && translations.ride.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.ride.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				removeScroll={true}
				className="theme-dark"
				color={"#2F2E2F"}
				title={translations && translations.ride.sections[0].title}
				text={translations && translations.ride.sections[0].text1}
				text2={translations && translations.ride.sections[0].text2}
				items={[
					{
						src: require("../../media/images/portfolio-images/ride/RIDE1.jpg")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/ride/RIDE4.jpg")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/ride/RIDE2.jpg")
							.default,
					},
				]}
			/>

			<section
				className="theme-light mid-padding"
				style={{ background: "#fff" }}
			>
				<ContainerBlock className="mid">
					<FullWidthVideo
						previewImage={
							require("../../media/images/portfolio-images/ride/RIDE4overlay.jpg")
								.default
						}
						srcVideo={
							require("../../media/images/portfolio-images/ride/RIDE5.mp4")
								.default
						}
					/>
				</ContainerBlock>
			</section>

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Ride.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Ride;
