import React, { useEffect } from "react";
import { any } from "prop-types";



import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText } from "../../ui-components/texts";
import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/villafago/image-cover.png";
import { ContainerBlock } from "../../ui-components/columns/components";
import { TitleWithScrollXImagesSection } from "./components-design";
import { FullWidthVideo } from "../../ui-components/videos";

const Villafago = ({
  setNextPageHref = () => {},
  setNextPageName = () => {},
}) => {
  useEffect(() => {
    setNextPageHref("/projects/capable");
    setNextPageName("Capable");
    document.title = "Villafago - Cutting Edge Digital Creative Studio";
  }, []);

  const { translations } = useTranslate();
  const peoples = [
    {
      fullname: "Andrea Anfuso",
      image: require("../../media/team-images/Andrea Anfuso.jpg").default,
      role: translations === null ? "" : translations.about.peoples[0].role,
      description:
        translations === null ? "" : translations.about.peoples[0].description,
    },
    {
      fullname: "Giuseppe Bussolari",
      image: require("../../media/team-images/Giuseppe Bussolari.jpg").default,
      role: translations === null ? "" : translations.about.peoples[8].role,
      description:
        translations === null ? "" : translations.about.peoples[8].description,
    },
    {
      fullname: "Samuele Olindo",
      image: require("../../media/team-images/Samuele Olindo.jpg").default,
      role: translations === null ? "" : translations.about.peoples[18].role,
      description:
        translations === null ? "" : translations.about.peoples[18].description,
    },
  ];

  const AnimatedNumber = ({ value }) => {
    const { number } = useSpring({
      from: { number: 0 },
      number: value,
      delay: 200,
      config: { mass: 1, tension: 20, friction: 10 },
    });
    return <animated.span>{number.to(n => n.toFixed(0))}</animated.span>;
  };

  return (
    <div className="project-page project-page-cips">
      <CoverPorfolio
        background="#121B1D"
        className="theme-dark"
        title="Villafago"
        white={true}
        text={translations && translations.villafago.intro}
        image={imageCover}
      />

      <DetailsProject
        client={"Top Building Group"}
        services={["Brand Identity", "Development"]}
        timeframe={"2023"}
        introduction={
          <>
            <LargeText>
              {translations && translations.villafago.descriptions[0]}
            </LargeText>
          </>
        }
      />

      <TitleWithScrollXImagesSection
        className="theme-dark"
        color={"#121B1D"}
        title={translations && translations.villafago.sections[0].title}
        text={translations && translations.villafago.sections[0].text1}
        items={[
          {
            src: require("../../media/images/portfolio-images/villafago/gallery/1.png")
              .default,
          },
          {
            src: require("../../media/images/portfolio-images/villafago/gallery/2.png")
              .default,
          },
          {
            src: require("../../media/images/portfolio-images/villafago/gallery/3.png")
              .default,
          },
          {
            src: require("../../media/images/portfolio-images/villafago/gallery/4.png")
              .default,
          },
        ]}
      />

     
      <PeopleOfMasamune peoples={peoples} />
      <div className="cta-single-project">
        <div className="cta-single-project-inner">
          <p>{translations && translations.ctasingleproject.text}</p>
          <PopupButton id="egC3mNyH">
            {translations && translations.ctasingleproject.button}
          </PopupButton>
        </div>
      </div>
    </div>
  );
};

Villafago.propTypes = {
  setNextPageHref: any,
  setNextPageName: any,
};

export default Villafago;
