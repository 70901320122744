import React, { useEffect } from "react";
import { any } from "prop-types";

import { PopupButton } from "@typeform/embed-react";

import CoverPorfolio from "../../components/cover-portfolio";
import PeopleOfMasamune from "../../components/people-of-masamune";
import { DetailsProject } from "../../components/portfolio-components";

import { LargeText, SmallTitle } from "../../ui-components/texts";

import { useTranslate } from "../../contexts/translate";

// images
import imageCover from "../../media/images/portfolio-images/locomeal/image-cover.png";
import {
	Column,
	Container,
	ContainerBlock,
} from "../../ui-components/columns/components";
import { FullWidthVideo } from "../../ui-components/videos";
import { TitleWithScrollXImagesSection } from "./components-design";

const Locomeal = ({ setNextPageHref = () => {}, setNextPageName = () => {} }) => {
	useEffect(() => {
		setNextPageHref("/projects/ride-5");
		setNextPageName("Ride 5");
		document.title = "Locomeal - Cutting Edge Digital Creative Studio";
	}, []);

	const { translations } = useTranslate();
	const peoples = [
		{
			fullname: "Andrea Anfuso",
			image: require("../../media/team-images/Andrea Anfuso.jpg").default,
			role: translations === null ? "" : translations.about.peoples[0].role,
			description:
				translations === null ? "" : translations.about.peoples[0].description,
		},
		{
			fullname: "Samuele Olindo",
			image: require("../../media/team-images/Samuele Olindo.jpg").default,
			role: translations === null ? "" : translations.about.peoples[18].role,
			description:
				translations === null ? "" : translations.about.peoples[18].description,
		},
	];

	return (
		<div className="project-page project-page-papini">
			<CoverPorfolio
				background="#2B502A"
				className="theme-dark"
                white
				title="LocoMeal"
				text={translations && translations.locomeal.intro}
				image={imageCover}
			/>

			<DetailsProject
				client={"locomeal"}
				services={["Coding, Web development"]}
				timeframe={"2023 - 2024"}
				introduction={
					<>
						<LargeText>
							{translations && translations.locomeal.descriptions[0]}
						</LargeText>
						<LargeText>
							{translations && translations.locomeal.descriptions[1]}
						</LargeText>
					</>
				}
			/>

			<TitleWithScrollXImagesSection
				removeScroll={true}
				className="theme-light"
				color={"#E7E9B3"}
				white={false}
				title={translations && translations.locomeal.sections[0].title}
				text={translations && translations.locomeal.sections[0].text1}
				
				items={[
					{
						src: require("../../media/images/portfolio-images/locomeal/gallery/4.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/locomeal/gallery/2.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/locomeal/gallery/3.png")
							.default,
					},
					{
						src: require("../../media/images/portfolio-images/locomeal/gallery/1.png")
							.default,
					},
				]}
			/>

		

			<PeopleOfMasamune peoples={peoples} />
			<div className="cta-single-project">
				<div className="cta-single-project-inner">
					<p>{translations && translations.ctasingleproject.text}</p>
					<PopupButton id="egC3mNyH">
						{translations && translations.ctasingleproject.button}
					</PopupButton>
				</div>
			</div>
		</div>
	);
};

Locomeal.propTypes = {
	setNextPageHref: any,
	setNextPageName: any,
};

export default Locomeal;
