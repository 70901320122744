export const primary = '#1a1a1a';
export const secondary = '#6e33ff';
export const magenta = '#e64baf';
export const yellow = '#fef552';
export const green = '#87fa97';
export const blue = '#62b8f9';
export const bgBlack = '#1a1a1a';
export const bgFirst = '#3377dc';
export const bgSecond = '#6e33ff';
export const bgGrey = '#f1f1f1';
export const peopleGrey= '#cbcbcb'
export const colorGrey1 = '#333333';
export const colorGrey2 = '#3f3f3f';
export const colorGrey3 = '#828282';
export const colorGrey4 = '#bdbdbd';