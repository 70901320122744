import React, { useEffect, useRef } from "react";
import { any } from "prop-types";
import { icons } from "../icons-manager";
import { ForceScrollXSticky, SettingsScrollX } from "./components";

const ForceScrollX = ({ id = null, children, style = {}, className }) => {
	useEffect(() => {
		const slider = document.querySelector("#forced-scroll .image-wrapper");
		let isDragging = false;
		let startX = 0;
		let scrollLeft = 0;

		const pointerDownHandler = (e) => {
			isDragging = true;
			slider.classList.add("dragging");
			startX = e.clientX || e.touches[0].clientX;
			scrollLeft = slider.scrollLeft;

			// Prevent default to avoid potential text selection issues
			e.preventDefault();
		};

		const pointerMoveHandler = (e) => {
			if (!isDragging) return; // Only move if dragging
			const x = e.clientX || e.touches[0].clientX;
			const walk = (x - startX) * 1.5; // Adjust scrolling speed if needed
			slider.scrollLeft = scrollLeft - walk;
		};

		const pointerUpHandler = () => {
			isDragging = false;
			slider.classList.remove("dragging");
		};

		// Attach event listeners for pointer events
		if (slider) {
			slider.addEventListener("pointerdown", pointerDownHandler);
			slider.addEventListener("pointermove", pointerMoveHandler);
			slider.addEventListener("pointerup", pointerUpHandler);
			slider.addEventListener("pointerleave", pointerUpHandler);
			slider.addEventListener("pointercancel", pointerUpHandler);
		}

		// Cleanup to remove listeners when component unmounts
		return () => {
			if (slider) {
				slider.removeEventListener("pointerdown", pointerDownHandler);
				slider.removeEventListener("pointermove", pointerMoveHandler);
				slider.removeEventListener("pointerup", pointerUpHandler);
				slider.removeEventListener("pointerleave", pointerUpHandler);
				slider.removeEventListener("pointercancel", pointerUpHandler);
			}
		};
	}, []);

	return (
		<SettingsScrollX
			style={style}
			id={id === null ? "forced-scroll" : id}
			className={`scroll-horizontal ${className}`}
		>
			{children}
		</SettingsScrollX>
	);
};

export const ScrollXImages = ({
	id,
	className,
	style = {},
	items = [],
	removeScroll = false,
}) => {
	const horizontalScrollRef = useRef();

	useEffect(() => {
		const slider = horizontalScrollRef.current;
		let isDragging = false;
		let startX = 0;
		let scrollLeft = 0;

		const pointerDownHandler = (e) => {
			isDragging = true;
			slider.classList.add("dragging");
			startX = e.clientX || e.touches[0].clientX;
			scrollLeft = slider.scrollLeft;

			// Prevent default to avoid potential text selection issues
			e.preventDefault();
		};

		const pointerMoveHandler = (e) => {
			if (!isDragging) return; // Only move if dragging
			const x = e.clientX || e.touches[0].clientX;
			const walk = (x - startX) * 1.5; // Adjust scrolling speed if needed
			slider.scrollLeft = scrollLeft - walk;
		};

		const pointerUpHandler = () => {
			isDragging = false;
			slider.classList.remove("dragging");
		};

		// Attach event listeners for pointer events
		if (slider) {
			slider.addEventListener("pointerdown", pointerDownHandler);
			slider.addEventListener("pointermove", pointerMoveHandler);
			slider.addEventListener("pointerup", pointerUpHandler);
			slider.addEventListener("pointerleave", pointerUpHandler);
			slider.addEventListener("pointercancel", pointerUpHandler);
		}

		// Cleanup to remove listeners when component unmounts
		return () => {
			if (slider) {
				slider.removeEventListener("pointerdown", pointerDownHandler);
				slider.removeEventListener("pointermove", pointerMoveHandler);
				slider.removeEventListener("pointerup", pointerUpHandler);
				slider.removeEventListener("pointerleave", pointerUpHandler);
				slider.removeEventListener("pointercancel", pointerUpHandler);
			}
		};
	}, []);

	return (
		<ForceScrollX
			style={style}
			className={`${className} no-padding`}
			horizontalScroll={horizontalScrollRef}
		>
			<div className="full-vw wrapper p-0 w-100 scroll-horizontal-start">
				<div
					className="image-wrapper start-images-center align-items-center"
					id={id}
					ref={horizontalScrollRef}
				>
					{items.map((item, index) => (
						<div key={index} className="image-wrapper-portfolio">
							{item.type !== "video" ? (
								<img src={item.src} alt="Image" />
							) : (
								<video src={item.src} autoPlay loop playsInline />
							)}
						</div>
					))}
					<img
						src={
							String(className).includes("light")
								? icons.arrow.light.right
								: icons.arrow.dark.right
						}
						style={{
							position: "absolute",
							bottom: "-42px",
							right: "0",
							width: "32px",
						}}
						alt="Scroll right"
					/>
				</div>
			</div>
		</ForceScrollX>
	);
};

export const TranslateScrollX = ({ items = [] }) => {
	return (
		<ForceScrollXSticky>
			<div
				className="wrapper-items translate-item-force"
				style={{ translate: "none" }}
			>
				{items.map((item, index) => (
					<div
						className="item-wrapper"
						style={{ minWidth: 700, maxWidth: 1200 }}
						key={index}
					>
						{item}
					</div>
				))}
			</div>
		</ForceScrollXSticky>
	);
};

ForceScrollX.propTypes = {
	children: any,
	style: any,
	className: any,
};

TranslateScrollX.propTypes = {
	items: any,
};

ScrollXImages.propTypes = {
	className: any,
	id: any,
	style: any,
	items: any,
};

export default ForceScrollX;
